.appContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #e5e5e5;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eeeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9b9b9b;
  border-radius: 16px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
