.panelHeading {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-right: 10px;
  z-index: 1;
  border-bottom: 1px solid transparent;
}

.panelHeading:hover {
  color: var(--tabColor, #1b87e6);
  cursor: pointer;
}

.activePanel {
  border-bottom: 1px solid var(--tabColor, #1b87e6);
  color: var(--tabColor, #1b87e6);
}
