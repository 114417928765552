.icon {
  cursor: pointer;
  display: flex;
}
.activeIcon {
  display: flex;
}
.toggle {
  height: 32px;
  width: 64px;
  border-radius: 16px;
  background: #e8e8e8;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.left {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #1b87e6;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #1b87e6;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
