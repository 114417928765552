.container {
  margin-bottom: 40px;
}

.noDataAvailable {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 40px;
  }
}
