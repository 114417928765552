.container {
  font-size: 12px;
  color: #545e6b;
  display: flex;
  align-items: center;
}
.leftSideContainer {
  display: flex;
  align-items: center;
}

.sortByLeftSide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftSideSwitcher {
  margin-right: 32px;
}
.ascOrDescIcon {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ascOrDescIcon:hover {
  border-radius: 2px;
}
.dropDown {
  border: 1px solid #d8d8d8;
  height: 32px;
  background-color: #fff;
  margin: 0 24px 0 16px;
  min-width: 100px;
}
.dropDown:hover {
  background-color: #fff;
}
.dropDownItem {
  line-height: 32px;
  width: 100px;
  padding-left: 8px;
  cursor: pointer;
}
.dropDownItem:hover {
  background-color: #e8e8e8;
}
.dropDownContent {
  left: 16px;
}
.dropdownText {
  width: 60px;
}

@media screen and (max-width: 768px) {
  .container {
    justify-content: space-between;
  }
  .leftSideContainerFlexStart {
    justify-content: flex-start;
  }
  .leftSideContainerSpaceBetween {
    flex-grow: 1;
    justify-content: space-between;
  }
}
