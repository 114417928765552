.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.idleContent {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 100px;
}

.resultScreenNoResponseDivHeight {
  height: 100px;
}

.liveFeedViewDropdown {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 16px;
  margin-bottom: 8px;
}
.sortByLiveFeedViewDropdown {
  display: flex;
}
.ascOrDescIcon {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}

.mobileView {
  display: none;
}

.commentList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  margin: 8px 0;
  max-height: 435px;
  padding-right: 16px;
  max-width: 914px;
}
.commentListForLiveSession {
  composes: commentList;
  max-height: calc(100vh - 200px);
}
.dropDown {
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  width: 130px;
}

.dropDownItem {
  display: flex;
  padding: 8px;
  align-items: center;
  width: 120px;
  line-height: 16px;
  font-size: 12px;
}

.dropDownItem:hover {
  background-color: #e8e8e8;
  cursor: pointer;
}
.starIcon {
  height: 16px;
  width: 16px !important;
}
.dropDownItemIcon {
  margin-right: 8px;
}

.paginationContainer {
  margin-right: 70px;
}

.dropDownContentClass {
  min-width: 140px;
}
.mobileHeader {
  display: none;
}

@media only screen and (max-width: 768px) {
  .commentList {
    padding-right: 8px;
    max-height: 445px;
  }
  .liveFeedViewDropdown {
    display: none;
  }

  .mobileView {
    display: flex;
    align-items: center;
  }
  .mobileHeader {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    height: 32px;
  }
  .viewSelectorIconMobile {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }

  .viewSelectorIconMobile:hover {
    cursor: pointer;
  }

  .selectedIcon {
    background-color: #eee;
  }
  .viewSelectorIconMobile:nth-child(2) {
    margin: 0 8px;
  }
  .mobileScreenCenterIcon {
    margin: 0 8px;
  }
}
