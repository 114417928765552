.chartRow {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 200px;
}
.chartRowInner {
  display: flex;
  flex-grow: 1;
  width: 100%;
}
.resultContainer {
  display: flex;
}

.questionLeaderboardColumn {
  align-self: center;
  margin-left: 40px;
}

@media only screen and (max-width: 768px) {
  .chartRow {
    margin: 0;
  }
  .resultContainer {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .questionLeaderboardColumn {
    margin: 0 0 24px 32px;
    align-self: flex-start;
  }
}
