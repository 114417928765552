.panelContainer {
  display: flex;
  width: 100%;
  position: relative;
}

.borderBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid  #d8d8d8;
}
