.container {
  display: flex;
  color: var(--title, #545e6b);
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  flex-direction: column;
}
.questionTypeText {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #9b9b9b;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.icon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}
.questionDetails {
  display: flex;
}
.titleContainer {
  display: flex;
}
.currentQuestionNumber {
  font-weight: 500;
}
.imageContainer {
  position: relative;
  margin-right: 16px;
}
.image {
  width: 80px;
  height: 80px;
  min-width: 80px;
  cursor: pointer;
  object-fit: cover;
}
.imageOverlay {
  position: absolute;
  top: 0;
  composes: image;
  opacity: 0;
  background-color: black;
}
.maximize {
  position: absolute;
  top: 33px;
  left: 33px;
}
.imageContainer:hover .imageOverlay {
  opacity: 0.7;
}
.difficultyLevelIndicatorContainer {
  font-size: 12px;
  display: flex;
  margin-left: 8px;
}

@media only screen and (max-width: 768px) {
  .container {
    font-size: 16px;
    line-height: 24px;
    flex-direction: column;
    align-items: flex-start;
  }

  .imageContainer {
    margin-top: 8px;
    margin-left: 0;
  }
}
