.chartContainer {
  display: flex;
  flex-direction: column;
}
.teamRankings {
  font-family: Fira Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #545e6b;
  margin-top: 16px;
  margin-bottom: 8px;
}
.innerContainer {
  display: flex;
}
.team {
  display: flex;
  margin-right: 22px;
  min-width: 175px;
}
.teamName {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}
.boldTeamName {
  composes: teamName;
  font-weight: 500;
  color: var(--main, #1b3380);
}
.percentageContainer {
  display: flex;
  align-items: center;
}
.percentage {
  font-size: 16px;
  font-weight: 400;
  margin-left: 8px;
}
.boldPercentage {
  font-weight: 500;
  color: var(--main, #1b3380);
}
.ribbonImg {
  width: 12px;
  margin-right: 8px;
  color: #1b3380;
  display: flex;
}

@media screen and (max-width: 768px) {
  .innerContainer {
    flex-direction: column;
  }
  .teamRankings {
    margin-bottom: 16px;
    border-top: 1px solid #e8e8e8;
    padding-top: 16px;
    margin-top: 0;
  }
  .team {
    margin-right: 0;
  }
  .teamRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .teamName {
    margin-left: 20px;
    margin-bottom: 8px;
  }
  .boldTeamName {
    margin-left: 0;
  }
  .percentage {
    font-size: 14px;
  }
}
