.header {
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    height: 48px;
    margin-right: 100px;
  }
  
  @media only screen and (min-width: 768px) {
    .header {
      position: relative;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .header {
      position: relative;
    }
  }
  
  .header .topHeader {
    background: #454545;
    border: none;
    display: block;
    font-size: 0;
    padding: 10px 15px;
    position: relative;
    height: 28px !important;
    box-sizing: content-box;
  }
  
  