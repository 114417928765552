.container {
  width: 100%;
}
.leftAndRightLabels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #545e6b;
}

.leftSideLabel {
  font-weight: 500;
  color: var(--main, #1B3380);
}
.bar {
  position: relative;
  height: 16px;
  background: #eee;
  width: 100%;
  border-radius: 4px;
  display: flex;
  min-width: 120px;
}
.leftSideBorderRadius {
  border-radius: 4px 0 0 4px;
}

.rightSideBorderRadius {
  border-radius: 0 4px 4px 0;
}
.borderRadius {
  border-radius: 4px;
}
.leftSideColor {
  background-color: var(--main, #1b3380);
}
.rightSideColor {
  background-color: var(--mainLight, #8dc3f2);
}
