.imagePopup {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 2.4rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0, 0, 0, 0.75);
    transition: 0.2s;
  }
  
  .imagePopupInner {
    align-self: flex-start;
    max-width: 48rem;
    margin: auto;
    transition: 0.4s;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }
  .imageRow {
    display: flex;
  }
  .questionImage {
    max-width: 700px;
    max-height: 700px;
  }
  
  .closeIcon {
    cursor: pointer;
    color: #ddd;
    margin-left: 10px;
    position: absolute;
    right: 100px;
  }
  
  .barWrap {
    display: flex;
    color: #d8d8d8;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    margin-right: 23px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
  
  .zoomBar {
    height: 2px;
    width: 128px;
    background: #d8d8d8;
    margin: 0 10px;
    position: relative;
    border-radius: 1px;
  }
  
  .slider {
    height: 15px;
    width: 15px;
    background: #1b87e6;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateY(50%) translateX(-50%);
    border-radius: 7.5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  .plusMinus {
    font-size: 22px;
    font-weight: bolder;
  }
  
  @media only screen and (max-width: 750px) {
    .imageRow {
      position: relative;
    }

    .closeIcon {
      right: 0;
      top: -15px;
    }

    .barWrap {
      display: none;
    }
  }