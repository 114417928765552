html,
body {
  height: 100%;
}

body {
  margin: 0 0 0 0;
  padding: 0;
  background-color: #f2f2f2;
  color: #545e6b;
  font-size: 12px;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: 'Fira Sans', sans-serif;
}

#root {
  height: 100%;
}

/* CSS for placeholder */

/* WebKit, Blink, Edge */
::-webkit-input-placeholder {
  color: #9b9b9b;
}

/* Mozilla Firefox 4 to 18 */
:-moz-placeholder {
  color: #9b9b9b;
}

/* Mozilla Firefox 19+ */
::-moz-placeholder {
  color: #9b9b9b;
}

/* Internet Explorer 10-11 */
:-ms-input-placeholder {
  color: #9b9b9b;
}

/* MS Edge */
::-ms-input-placeholder {
  color: #9b9b9b;
}

/* Most modern browsers support this now */
::placeholder {
  color: #9b9b9b;
}
