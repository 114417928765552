.loaderContainer {
  display: flex;
  align-items: center;
  margin: auto;
}

.left {
  background-color: #fff;
  flex-grow: 1;
  overflow-y: hidden;
  position: relative;
  width: 100%;
}

.leftWrapper {
  width: 100%;
}

.questionsContainer {
  margin: 0 auto;
  max-width: 1400px;
}

.questionsContainerForPoll {
  margin: 0 auto;
  max-width: 890px;
}

.panelContainer {
  margin: 16px 0;
}

.panelText {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.panelText::first-letter {
  text-transform: capitalize;
}
.dashboardReport {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  min-width: 334px;
}
.responseSummaryText {
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
}
.innerContainer {
  padding: 0 32px;
  overflow: auto;
  height: calc(100vh - 230px);
  width: 100%;
}
.mainWrapper {
  display: flex;
}
