.container {
  margin-bottom: 40px;
}

.spinnerContainer {
  height: 285px;
}

.liveFeedStatsContainer {
  margin-top: 32px;
  max-width: 890px;
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 40px;
  }
  .liveFeedStatsContainer {
    margin-top: 16px;
  }
}
