.loaderContainer {
  display: flex;
  align-items: center;
  margin: auto;
}

.left {
  background-color: #fff;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
}

.leftWrapper {
  position: absolute;
  width: 100%;
}

.questionsContainer {
  margin: 0 16px;
}


.panelContainer {
  margin-bottom: 24px;
}

.panelText {
  font-size: 12px;
  font-weight: 400;
}
.panelText::first-letter {
  text-transform: capitalize;
}

.dashboardReport {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .dashboardReport {
    align-items: center;
  }
}
