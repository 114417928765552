.shareIconContainer {
  margin-left: 32px;
}

.responseCountIcon {
  display: block;
  margin-right: 8px;
}

@media screen and (max-width: 1000px) {
  .shareIconContainer {
    display: flex;
    justify-content: flex-end;
    margin-left: 0;
    min-width: 0;
  }
}

@media screen and (max-width: 768px) {
  .copyLinkText {
    display: none;
  }

  .responseCountIcon {
    margin: 8px;
  }
}
