.productIcon {
  width: 60px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 62% auto;
  float: left;
  outline: none;
}

.qpIcon {
  box-sizing: content-box;
}

ul,
li,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  outline: 0;
}

button:focus,
button:active {
  outline: 0;
}

a {
  color: #004693;
  transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
}

a:hover,
a:focus {
  color: #0095dc;
  outline: none;
  text-decoration: none;
}

.qpProductDropdown {
  display: inline-block;
  margin: 0 10px 0 5px;
  position: relative;
  vertical-align: top;
}

.qpProductDropdown .dropdownToggle,
.dropdownToggle {
  height: 27px;
  background: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  position: relative;

  display: flex;
  align-items: center;
  z-index: 3;
}

@media only screen and (min-width: 970px) {
  .qpProductDropdown .dropdownToggle {
    padding-left: 5px;
  }
}

@media only screen and (min-width: 1024px) {
  .qpProductDropdown .dropdownToggle {
    padding-top: 2px;
  }
}

@media only screen and (min-width: 1200px) {
  .qpProductDropdown .dropdownToggle {
    font-size: 18px;
  }
}

.qpProductDropdown .dropdownToggle .faCaretDown {
  transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
}

.qpProductDropdown .dropdownToggle:before {
  height: 48px;
  background: #676767;
  content: '';
  left: -80px;
  overflow: hidden;
  position: absolute;
  right: -8px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  background: #1b87e6;
  border-right: 1px solid #ffffff;
}

.qpProductDropdown .dropdownToggle:hover {
  background: #676767;
}

.qpProductDropdown .dropdownToggle:hover:after {
  height: 48px;
  content: '';
  left: -80px;
  overflow: hidden;
  position: absolute;
  right: -8px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  background: #676767;
  border-right: 1px solid #ffffff;
}

.qpProductDropdown .dropdownToggle .text {
  margin-right: 5px;
  display: flex;
  align-items: center;
  z-index: 3;
}

.qpProductDropdown .dropdownToggle .text img {
  padding-right: 10px;
}

.dropdownIcon {
  transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  z-index: 3;
}

.dropdownIconOpen {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}

.qpProductDropdown .dropdownMenu {
  position: absolute;
  width: 300px;
  margin-top: 1px !important;
  padding-top: 1px !important;
  right: auto;
  background: #ffffff;
  top: 0;
  left: -20px;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px 0px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 10ms;
  -moz-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 10ms;
  -o-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 10ms;
  -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 10ms;
}

.qpProductDropdown .dropdownMenuOpen {
  opacity: 1;
  visibility: visible;
  top: 36px;
  transition-delay: 0s, 0s, 0.3s;
}

.qpProductDropdown .dropdownMenu > li {
  padding-right: 50px;
  position: relative;
  transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  z-index: 2;
}

.qpProductDropdown .dropdownMenu > li .productLink {
  height: 60px;
  display: block;
  font-weight: 300;
  padding: 0;
  position: relative;
  width: 260px;
  opacity: 3;
}

.qpProductDropdown .dropdownMenu > li .productLink:hover,
.qpProductDropdown .dropdownMenu > li .productLink:focus {
  background: none;
}

.qpProductDropdown .dropdownMenu > li .productLink .productIcon {
  width: 60px;
  height: 100%;
  float: left;
  position: relative;
}

.qpProductDropdown .dropdownMenu > li .productLink .productIcon img {
  width: 35px;
  left: 12.5px;
  margin: 0;
  position: absolute;
  top: 12.5px;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.qpProductDropdown .dropdownMenu > li .productLink .productText {
  color: #555;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 0 60px;
  overflow: hidden;
  position: relative;
  top: 50%;
  text-overflow: ellipsis;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  white-space: nowrap;
  opacity: 3;
}

.qpProductDropdown .dropdownMenu > li .productLink .productText small {
  color: #808792;
  display: block;
  font-weight: 300;
}
.rightArrowIcon {
  position: absolute;
  left: 275px;
  top: 25px;
}
.icon {
  height: 15px;
  width: 15px !important;
}
.productList {
  position: absolute;
  left: 0;
  height: 60px;
  margin-left: 0;
  background: #fff;
  display: flex;
  top: 0;
  z-index: -1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 10ms;
  -moz-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 10ms;
  -o-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 10ms;
  -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 10ms;
}
.productBlock {
  width: 92px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeProductBlock {
  composes: productBlock;
  background: rgba(0, 0, 0, 0.03);
}

.surveys:hover .productList {
  left: 115%;
  opacity: 1;
}
.surveys:hover .rightArrowIcon {
  display: none;
}
.surveys:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.borderTop {
  width: 0;
  height: 4px;
  content: '';
  left: 0;
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  -webkit-opacity: 0.5;
  opacity: 0.5;
  position: absolute;
  top: 0;
  background-color: red;
  z-index: 3;
}

.surveys:hover .borderTop {
  width: 100%;
}

.productBorderTop {
  composes: borderTop;
  width: 92px !important;
  left: auto;
}
.activeProduct {
  composes: productBorderTop;
  opacity: 1 !important;
}
.productBlock:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.03);
  box-shadow: none;
}
.product {
  font-size: 13px;
  font-weight: 400;
  color: #555;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.activeProductBlock .product {
  color: #004693;
}
.productBlock:hover .product {
  color: #004693;
}
.productBlock:hover .productBorderTop {
  opacity: 1 !important;
}
.product span {
  margin-top: 3px;
}
.productImg {
  display: inline-block;
}
.hoverImg {
  display: none;
}

.productBlock:hover .hoverImg {
  display: inline-block;
}

.productBlock:hover .productImg {
  display: none;
}
.whiteBack {
  width: 300px;
  height: 60px;
  background-color: #fff;
  position: absolute;
}
