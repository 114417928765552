.headerContainer {
  display: flex;
  margin: 32px;
  margin-bottom: 0;
  flex-direction: column;
}
.sessionNameRow {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
.sessionName {
  font-size: 24px;
  color: var(--title, #545e6b);
  display: flex;
  justify-content: center;
  align-items: center;
}
.backButton {
  color: var(--title, #545e6b);
  margin-right: 8px;
}

.sessionTimeContainer,
.responseCountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9b9b9b;
}
.sessionDetailsRow {
  display: flex;
  margin-top: 16px;
  line-height: 20px;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
  color: #9b9b9b;
  font-weight: 400;
}
.livePollType {
  height: 16px;
  width: 16px;
  font-weight: 400;
}
.livePollTypeText {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.responseCountContainer {
  margin: 0 32px;
}

.sessionTimeContainer {
  margin-left: 24px;
  font-size: 14px;
}

.responseCount {
  font-size: 14px;
  font-weight: 500;
}

.participantText {
  margin-left: 4px;
  font-size: 14px;
}
.questionCount {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.questionIcon {
  margin-right: 8px;
  height: 16px;
  width: 16px !important;
}
.questionText {
  font-weight: 400;
  margin-left: 4px;
}
.sessionTime {
  margin-right: 4px;
}

.createdDate {
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.calendarIcon {
  height: 16px;
  width: 16px !important;
  margin-right: 8px;
}
.iconSpan {
  margin-right: 8px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
}

.copyReportRow {
  display: flex;
  position: relative;
  height: 36px;
}

.downloadReportPopup {
  background: #fff;
  color: #545e6b;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .176);
  width: 150px;
  right: auto;
  top: 100%;
  position: absolute;
  font-size: 12px;
  font-weight: 500;
}

.popupTitle {
  margin: 8px;
}

.popupItem {
  padding: 8px 16px;
  font-weight: 400;
  cursor: pointer;
}

.popupItem:hover {
  background: #f5f5f5;
  color: #545e6b;
}
@media only screen and (max-width: 768px) {
  .headerContainer {
    margin: 16px 0;
  }
  .sessionDetailsRow {
    flex-wrap: wrap;
    height: 72px;
  }
  .sessionName {
    font-size: 20px;
  }
    
  .livePollTypeText,
  .createdDate,
  .participantText,
  .responseCount,
  .questionCount,
  .sessionTimeContainer {
    font-size: 12px;
  }
  .downloadCsvText{
    display: none;
  }
  .shareIconContainer {
    min-width: 0;
  }
}
