.container {
  margin: 0;
}
.innerContainer {
  margin: 32px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0;
  }
}
