.dataNotAvailableContainer {
  display: flex;
  min-height: 115px;
  justify-content: center;
  align-items: center;
}
.chartContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 40px;
}
.sortByContainer {
  margin: 16px 0;
}
.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
}
.answerRow {
  display: flex;
  min-width: 400px;
  flex-grow: 1;
  font-size: 18px;
  margin: 12px 0;
  align-items: center;
  width: calc(100% - 60px);
}
.answerTextRow {
  max-width: 440px;
  display: flex;
  margin-right: 8px;
  min-width: 200px;
  flex-grow: 1;
  flex: 55%;
}
.answerText {
  display: flex;
  align-items: center;
  font-weight: 400;
  color: #545e6b;
}
.answerImage {
  min-width: 60px;
  width: 60px;
  height: 40px;
  border-radius: 2px;
  margin-right: 16px;
  object-fit: cover;
}
.barRow {
  min-width: 200px;
  max-width: 380px;
  display: flex;
  height: 24px;
  flex: 45%;
}
.percentage {
  position: relative;
  border-radius: 0 4px 4px 0;
}
.percentageText {
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  width: 60px;
  right: -68px;
}
.centerLine {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #9b9b9b;
}
.bottomLine {
  position: absolute;
  bottom: 0;
  height: 1px;
  background: #9b9b9b;
}
.correctIcon {
  top: 2px;
  position: absolute;
  bottom: 2px;
  margin-left: 8px;
}
.icon {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 768px) {
  .centerLine {
    visibility: hidden;
  }
  .bottomLine {
    visibility: hidden;
  }
  .answerRow {
    margin: 0;
    padding: 16px 0;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #e8e8e8;
  }
  .answerRow:first-child {
    border-top: 1px solid #e8e8e8;
  }
  .answerTextRow {
    flex: 0;
  }
  .barRow {
    min-width: 80%;
    height: 16px;
    flex: 0;
  }
  .percentage {
    height: 16px;
  }
  .percentageText {
    font-size: 14px;
    position: absolute;
    top: -2px;
  }
  .answerText {
    margin-bottom: 8px;
  }
  .answerImage {
    margin-bottom: 8px;
  }
  .answerRow {
    min-width: 100%;
  }
}
