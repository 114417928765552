.resultContainerWrapper {
  display: flex;
  width: 334px;
  height: calc(100vh - 230px);
  transition: background-color 700ms;
  color: #545e6b;
  border-left: 1px solid #e8e8e8;
  box-sizing: content-box;
  overflow: auto;
  padding-right: 12px;
  overflow-x: hidden;
  min-height: 100%;
}
.questionHeader {
  margin-bottom: 16px;
  margin-top: 32px;
}
.questionHeaderForLiveSession {
  margin: 16px 0;
}
.ribbonImg {
  height: 16px;
  width: 12px;
  display: flex;
  color: #1b3380;
}
.questionText {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}
.secondRow {
  margin-left: 12px;
}
.itemContainer {
  margin-left: 16px;
  height: 100%;
  width: 100%;
}
.toggles {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}
.teamEnabledToggles {
  composes: toggles;
  justify-content: space-between;
}
.noDataAvailableMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 16px;
}

.answerRowsContainer {
  position: relative;
  padding-bottom: 20px;
  max-width: 318px;
}

.leaderboardTable {
  text-align: left;
  font-size: 16px;
}
.leaderboardRow {
  display: flex;
  justify-content: space-between;
  line-height: 28px;
}
.leaderboardRowTeam {
  composes: leaderboardRow;
  height: 64px;
  align-items: center;
  margin-top: 16px;
}
.leftSide {
  display: flex;
  flex: 5;
}
.leftSideForTeams {
  composes: leftSide;
  align-items: center;
}
.count {
  text-align: center;
}
.sequenceNum {
  text-align: right;
  width: 24px;
  margin-right: 8px;
}
.sequenceNumber {
  text-align: left;
  width: 28px;
  margin-right: 16px;
}
.colorBox {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 8px;
}
.progress {
  padding: 0;
  height: 4px;
  overflow: hidden;
  position: relative;
}

.bar {
  float: left;
  height: 100%;
  background: var(--main, #1f357b);
}

.percent {
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: var(--main, #1f357b);
  margin-left: 4px;
}

.name {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.accuracy {
  margin-left: 16px;
  width: 140px;
}

.percent {
  width: 70px;
}
.boldLeaderBoardRow {
  color: var(--main, #1b3380);
  font-weight: 500;
}
.checkIcon {
  color: #f5f5f5;
}
@media screen and (max-width: 768px) {
  .resultContainerWrapper {
    width: 100%;
      overflow: hidden;
      height: auto;
      border-left: none;
  }
  .answerRowsContainer {
    max-width: 100%;
  }
  .questionHeader {
   display: none;
  }
}
